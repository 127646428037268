import { withJsonFormsControlProps } from '@jsonforms/react';
import { rankWith, scopeEndsWith, and } from '@jsonforms/core';
import { withVanillaControlProps } from '@jsonforms/vanilla-renderers';
import { parseISO } from 'date-fns';

import { LastModified } from '../ui/LastModified';

const LastModifiedComponent = ({ data }) => {
	return <LastModified datetime={parseISO(data)} />;
};

export const LastModifiedControl = {
	tester: rankWith(2000, scopeEndsWith('updated')),
	renderer: withVanillaControlProps(withJsonFormsControlProps(LastModifiedComponent)),
};
