module.exports = {
	appDisplayName: 'Unbabel',
	appDisplayLogo: '/unbabel-text-logo.png',
	appVersion: '1.0.0' || require('../package.json').version,
	componentLayout: 'default-vertical',
	componentFilter: {
		active: true,
		allTab: false,
		recentlyAddedTab: true,
		installedTab: true,
		categories: ['Marketing', 'Sales & CRM', 'Commerce', 'Social', 'Productivity', 'Finance'],
	},
};
