import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { PuzzleIcon, AdjustmentsIcon, LogoutIcon } from '@heroicons/react/outline';
import { logoutUser } from '../actions/logout';
import config from '../frigg.config';

function Sidebar({ logoutUser }) {
	return (
		<aside className="z-20 hidden w-64 overflow-y-auto bg-white md:block flex-shrink-0">
			<div className="h-[48px] bg-[#040237] py-2">
				<Link className="ml-6 text-2xl font-bold text-white inline-flex" to="/apps">
					<img
						src={'https://unbabel.com/wp-content/themes/unbabel/dist/images/favicon/safari-pinned-tab.svg'}
						className="w-7 h-7"
						style={{ filter: 'invert(1) contrast(3) brightness(3) grayscale(1) saturate(3)	' }}
					/>
					<span className="ml-4">{config.appDisplayName}</span>
				</Link>
			</div>
			<div className="py-4 text-gray-500">
				<ul>
					{/* <li className="relative">
						<NavLink
							to="/unbabel"
							className="px-6 py-3 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800"
							activeClassName="text-gray-800 border-l-4 border-[#ec611b] bg-[#faf5ec]"
						>
							<img
								src={'https://unbabel.com/wp-content/themes/unbabel/dist/images/favicon/safari-pinned-tab.svg'}
								className="w-5 h-5"
							/>
							<span className="ml-4">Unbabel</span>
						</NavLink>
					</li> */}
					<li className="relative">
						<NavLink
							to="/integrations"
							className="px-6 py-3 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800"
							activeClassName="text-gray-800 border-l-4 border-[#ec611b] bg-[#faf5ec]"
						>
							<PuzzleIcon className="w-5 h-5" />
							<span className="ml-4">Integrations</span>
						</NavLink>
					</li>
					{/* <li className="relative">
						<NavLink
							to="/settings"
							className="px-6 py-3 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800"
							activeClassName="text-gray-800 border-l-4 border-[#ec611b] bg-[#faf5ec]"
						>
							<AdjustmentsIcon className="w-5 h-5" />
							<span className="ml-4">Settings</span>
						</NavLink>
					</li> */}
					<li className="relative">
						<NavLink
							to="/logout"
							className="px-6 py-3 inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800"
						>
							<LogoutIcon className="w-5 h-5" />
							<span className="ml-4">Logout</span>
						</NavLink>
					</li>
				</ul>
			</div>
		</aside>
	);
}

export default connect(null, { logoutUser })(Sidebar);
