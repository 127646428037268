import React from 'react';
import { connect } from "react-redux";
import { useLocation} from 'react-router';
import { toast } from 'react-toastify';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import API from "../api/api";

function ManageAuthPage(props) {
  const [spaceId, setSpaceId] = React.useState('');
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const canDismiss = React.useRef(false);

  const handleChange = (event) => {
    setSpaceId(event.target.value);
  };

  const handleCancel = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    canDismiss.current = true;
    setIsDialogOpen(false);
    props.history.push("/integrations");
  };

  const handleSave = async () => {
    const api = new API();

    if (!props.authToken) {
      return toast.error('No session found! Please login.');
    }

    api.setJwt(props.authToken);
    setIsProcessing(true);
    let integration;

    try {
      integration = await api.updateIntegration(
        props.integrationId,
        { spaceId }
      );
    } catch(e) {
      toast.error('An error ocurred updating integration');
      setIsProcessing(false);
      return console.error(e);
    }

    setIsProcessing(false);

    if (integration.error) {
      toast.error('An error ocurred updating integration');
      console.error(integration.error);
    } else {
      canDismiss.current = true;
      props.history.push("/integrations");
    }
  };

  const spacesItems = props.spaces.map((item) => (
    <MenuItem key={item.id} value={item.id}>
      {item.name}
    </MenuItem>
  ));

  // Prevent user from leaving without warning if connection incomplete
  // Solution: https://stackoverflow.com/a/73045350/2202143
  const prevLocation = useLocation().pathname;
  React.useEffect(() => {
    const unlisten = props.history.listen((location) => {
      if (!canDismiss.current && location.pathname !== prevLocation) {
        props.history.push(prevLocation);
        setIsDialogOpen(true);
      }
    });

    return () => {
      unlisten()
    }
  }, []);

  return (
      <main className="h-full pb-16 overflow-y-auto">

        <Dialog
          open={isDialogOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          >
          <DialogTitle id="alert-dialog-title">
            {"Incomplete Connection"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {"Please note that you won't be able to use this integration until you set "
              + "the space to connect with. You can set it later when visiting the integration."}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDialogOpen(false)} autoFocus>
              Finish Connecting
            </Button>
            <Button onClick={handleClose} autoFocus>
              Leave
            </Button>
          </DialogActions>
        </Dialog>

        <div className="container px-6 mx-auto grid">
          <h2 className="my-6 text-2xl font-semibold text-gray-700">Contentful</h2>
          <p>In order to finish connecting your account, please select a space: </p>
          <br />
          <FormControl>
            <InputLabel id="demo-simple-select-label">Space</InputLabel>
            <Select
              className="mb-[8px] w-80"
              value={spaceId}
              onChange={handleChange}
              label="Space"
              >
              {spacesItems}
            </Select>
          </FormControl>
          <Stack
            style={{ position: 'relative' }}
            spacing={1}
            direction="row">
            <Button
              variant="outlined"
              onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={!spaceId || isProcessing}
              disableElevation>
              Save
            </Button>
            {isProcessing && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '22%',
                  left: '7.5%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
                />
            )}
          </Stack>
        </div>
      </main>
  );
}

// this function is used by redux connect() and tells the component
// which values we want to map from the store into props
function mapStateToProps({ auth }) {
  return {
    integrationId: auth.integrationId,
    spaces: auth.spaces,
    authToken: auth.token,
  };
}

// connects this component to the redux store.
export default connect(mapStateToProps)(ManageAuthPage);
