import clsx from 'clsx';

export const Status = ({ animated = false, children, ...props }) => {
	const status = children?.toLowerCase();
	return (
		<div className="inline-flex" {...props}>
			<div
				className={clsx({
					'relative h-[16px] w-[16px] rounded-full mr-2': true,
					'bg-[#8BC34A]': status === 'published',
					'bg-[#FFC107]': status === 'draft',
					'bg-[#3843D0]': status === 'in progress',
					'bg-[#F44336]': status === 'failure',
				})}
			>
				{status === 'in progress' && animated && (
					<div className="absolute h-full w-full rounded-full mr-2 bg-[#3843D0] animate-ping"></div>
				)}
			</div>
			<div className="text-xs text-[#8D98AA] capitalize">{status}</div>
		</div>
	);
};
