import { withJsonFormsControlProps } from "@jsonforms/react";
import {
  rankWith,
  scopeEndsWith,
  isBooleanControl,
  and,
} from "@jsonforms/core";
import { withVanillaControlProps } from "@jsonforms/vanilla-renderers";
import { Locale } from "../ui/Locale";

const LocaleComponent = (props) => {
  const { data } = props;

  const locale = {
    en: "English (US)",
    es: "Español (ES)",
    as: "Assamese (IN)",
    af: "Afrikaans (ZA)",
    "en-us": "English (US)",
    "es-mx": "Spanish (MX)",
    "de-de": "German (DE)",
    de: "German (DE)",
    "fr-fr": "French (FR)",
    fr: "French (FR)",
    "pt-br": "Portuguese (BR)",
    "pt-pt": "Portuguese (BR)",
    "ru-ru": "Russian (RU)",
    "zh-cn": "Chinese (CN)",
    "zh-hans": "Chinese (CN-HANS)",
    "zh-tw": "Chinese (TW)",
    "ja-jp": "Japanese (JP)",
    "it-it": "Italiano (IT)",
    it: "Italiano (IT)",
    "nl-nl": "Dutch (NL)",
    nl: "Dutch (NL)",
    "pl-pl": "Polish (PL)",
    ko: "Korean (KR)",
  };
  const language = locale[data] || "";

  return <>{language ? <Locale locale={language} /> : `${data}`}</>;
};

export const LocaleControl = {
  tester: rankWith(2000, scopeEndsWith("language")),
  renderer: withVanillaControlProps(withJsonFormsControlProps(LocaleComponent)),
};
