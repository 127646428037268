import { MaterialLayoutRenderer } from '@jsonforms/material-renderers';
import { Accordion, AccordionDetails, AccordionSummary, Hidden, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { withJsonFormsLayoutProps, withJsonFormsArrayLayoutProps } from '@jsonforms/react';
import { rankWith, uiTypeIs, isObjectArrayWithNesting } from '@jsonforms/core';

const AccordionGroupRenderer = (props) => {
	const { uischema, schema, path, visible, renderers } = props;

	const layoutProps = {
		elements: uischema.elements,
		schema: schema,
		path: path,
		direction: 'column',
		visible: visible,
		uischema: uischema,
		renderers: renderers,
	};
	return (
		<Hidden xsUp={!visible}>
			<Accordion>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>{uischema.label}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<MaterialLayoutRenderer {...layoutProps} />
				</AccordionDetails>
			</Accordion>
		</Hidden>
	);
};

export default withJsonFormsLayoutProps(AccordionGroupRenderer);
export const accordionGroupRenderer = rankWith(1, isObjectArrayWithNesting);

// export default withJsonFormsLayoutProps(AccordionGroupRenderer);
// export const accordionGroupRenderer = rankWith(1000, uiTypeIs('Array'));
