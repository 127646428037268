import React from 'react';
import { makeStyles } from '@mui/styles';

import CompatibleArea from './CompatibleArea';
import InstalledArea from './InstalledArea';
import ListWorkflowData from './ListWorkflowData';

const useStyles = makeStyles({
    container: {
        padding: '2em 0',
        width: '100%',
    },
});

const Action = ({ integrationId, extensionId }) => {
    const classes = useStyles();

    return <div className={classes.container}>
        <CompatibleArea>
            <InstalledArea extensionId={extensionId}>
                <ListWorkflowData integrationId={integrationId} extensionId={extensionId}></ListWorkflowData>
            </InstalledArea>
        </CompatibleArea>
    </div>;
};

export const ActionType = 'LIST_WORKFLOW_DATA';

export default Action;
