import React from "react";
import * as SwitchPrimitive from "@radix-ui/react-switch";

export const Switch = ({ value, updateValue, label, description }) => {
  return (
    <form>
      <div className="flex items-center">
        <SwitchPrimitive.Root
          className="rounded-full h-[20px] w-[32px] outline-none position-relative bg-transparent border-2"
          style={{ borderColor: value ? "#3843D0" : 'black' }}
          checked={value}
          onCheckedChange={() => updateValue(!value)}
        >
          <SwitchPrimitive.Thumb
            className={`block w-[12px] h-[12px] rounded-full`}
            style={{
              backgroundColor: value ? "#3843D0" : 'black',
              transition: "transform 100ms",
              willChange: "transform",
              transform: value ? "translateX(15px)" : "translateX(2px)",
            }}
          />
        </SwitchPrimitive.Root>
        <div className="pl-[12px]">
          <div className="text-[16px] font-bold">{label}</div>
          {description ? (
            <div className="text-[12px]" style={{ color: "#696C72" }}>
              {description}
            </div>
          ) : null}
        </div>
      </div>
    </form>
  );
};
