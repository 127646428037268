import React from 'react';
import clsx from 'clsx';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { Checkbox } from './Checkbox';
import { Dropdown } from './Dropdown';

export const Accordion = React.forwardRef(({ children, className, ...props }, forwardedRef) => {
	return (
		<AccordionPrimitive.Root
			className={clsx(
				'border-t-[1px] border-[#d0d4da] mt-px overflow-hidden first:mt-0 last:border-b-[1px]',
				className
			)}
			{...props}
			ref={forwardedRef}
		>
			{children}
		</AccordionPrimitive.Root>
	);
});

export const AccordionItem = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
	<AccordionPrimitive.Item
		className={clsx('border-t-[1px] border-[#d0d4da] mt-px overflow-hidden first:mt-0 last:border-b-[1px]', className)}
		{...props}
		ref={forwardedRef}
	>
		{children}
	</AccordionPrimitive.Item>
));

export const AccordionTrigger = React.forwardRef(({ children, className, ...props }, forwardedRef) => {
	// const { pipeline, setPipeline, translate, handleTranslate, ...rest } = props;

	return (
		<AccordionPrimitive.Header className="flex">
			<AccordionPrimitive.Trigger
				className={clsx(
					'text-[#343941] group flex flex-1 pl-6 pr-14 flex-nowrap items-center h-[45px] cursor-default bg-white text-[15px] leading-none outline-none',
					className
				)}
				{...props}
				ref={forwardedRef}
			>
				<span>
					<Checkbox id="1" />
				</span>
				<span className="px-4">
					<ChevronDownIcon
						className="text-[#343941] ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform duration-300 group-data-[state=open]:rotate-180"
						aria-hidden
					/>
				</span>
				<div className="flex items-center w-full justify-between">
					<div className="flex items-center">{children}</div>

					{/* <div className="inline-flex items-center w-[112px]">
						<div
							className={clsx({
								'relative h-[16px] w-[16px] rounded-full mr-2': true,
								'bg-[#8BC34A]': props.translationStatus === 'published',
								'bg-[#FFC107]': props.translationStatus === 'draft',
								'bg-[#3843D0]': props.translationStatus === 'in progress',
								'bg-[#F44336]': props.translationStatus === 'failure',
							})}
						>
							{props.translationStatus === 'in progress' && newAction && (
								<div className="absolute h-full w-full rounded-full mr-2 bg-[#3843D0] animate-ping"></div>
							)}
						</div>
						<div className="text-xs text-[#8D98AA] capitalize">{props.translationStatus}</div>
					</div> */}
				</div>
			</AccordionPrimitive.Trigger>
		</AccordionPrimitive.Header>
	);
});

export const AccordionContent = React.forwardRef(({ children, className, ...props }, forwardedRef) => {
	// const { newAction = false, pipeline, setPipeline, translate, handleTranslate, ...rest } = props;

	return (
		<AccordionPrimitive.Content
			className={clsx(
				'flex items-center pl-6 pr-12 bg-gray-100 border-t-[1px] border-[#d0d4da] data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden text-[15px]',
				className
			)}
			{...props}
			ref={forwardedRef}
		>
			<span>
				<Checkbox id={props.value} />
			</span>
			<div className="flex items-center w-full justify-between">
				<div className="flex py-[15px] pl-24 text-[#8D98AA]">{children}</div>

				{/* <div className="flex items-center justify-end">
					<div className="inline-flex mr-8">
						<input
							className="w-[100px] text-sm form-input rounded h-[32px] mr-[4px]"
							type="text"
							placeholder="Pipeline ID"
							onChange={(e) => setPipeline(e.target.value)}
						/>
						<Dropdown translate={translate} handleTranslate={handleTranslate} />
					</div>

					<div className="inline-flex items-center w-[120px]">
						<div
							className={clsx({
								'relative h-[16px] w-[16px] rounded-full mr-2': true,
								'bg-[#8BC34A]': props.translationStatus === 'published',
								'bg-[#FFC107]': props.translationStatus === 'draft',
								'bg-[#3843D0]': props.translationStatus === 'in progress',
								'bg-[#F44336]': props.translationStatus === 'failure',
							})}
						>
							{props.translationStatus === 'in progress' && newAction && (
								<div className="absolute h-full w-full rounded-full mr-2 bg-[#3843D0] animate-ping"></div>
							)}
						</div>
						<div className="text-xs text-[#8D98AA] capitalize">{props.translationStatus}</div>
					</div>
				</div> */}
			</div>
		</AccordionPrimitive.Content>
	);
});
