import React, { useState } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { Link } from "react-router-dom";

function ToggleSwitch({ disconnectIntegration }) {
	const [dropdown, setDropdown] = useState(false);

	const dropDownDisconnect = async (e) => {
                e.stopPropagation();
                await disconnectIntegration();
                setDropdown(false);
	};

	return (
		<>
			<DotsVerticalIcon
                                onClick={(e) => {
                                  setDropdown(!dropdown);
                                  e.stopPropagation();
                                }}
				className="h-6 w-6 mt-4 ml-auto text-gray-500 cursor-pointer"
			/>

			{dropdown ? (
                          <ClickAwayListener onClickAway={() => setDropdown(false)}>
				<div
					className="origin-top-right mt-2 absolute right-0 w-40 z-50 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
					role="menu"
					aria-orientation="vertical"
					aria-labelledby="menu-button"
					tabIndex="-1"
				>
					<div className="py-1" role="none">
						<span
							className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-200"
							role="menuitem"
							tabIndex="-1"
							id="menu-item-1"
							onClick={dropDownDisconnect}
						>
							Disconnect
						</span>
					</div>
				</div>
                          </ClickAwayListener>
			) : null}
		</>
	);
}

export default ToggleSwitch;
