import { withJsonFormsControlProps } from '@jsonforms/react';
import { rankWith, scopeEndsWith, isBooleanControl, and } from '@jsonforms/core';
import { withVanillaControlProps } from '@jsonforms/vanilla-renderers';
import { Input } from '../ui/Input';

const InputComponent = (props) => {
	const { data, schema, path, handleChange } = props;

	return <Input value={data} handleChange={handleChange} path={path} placeholder={schema.placeholder || ''} />;
};

export const InputControl = {
	tester: rankWith(2000, scopeEndsWith('pipelineId')),
	renderer: withVanillaControlProps(withJsonFormsControlProps(InputComponent)),
};
