import React, { useState, useCallback } from 'react';
import { composePaths, rankWith, scopeEndsWith, createId } from '@jsonforms/core';
import { JsonFormsDispatch, withJsonFormsArrayControlProps, withJsonFormsControlProps } from '@jsonforms/react';
import { withVanillaControlProps } from '@jsonforms/vanilla-renderers';
import { Accordion, AccordionSummary, AccordionDetails, Avatar, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChildLayoutRenderer from './ChildLayoutRenderer';
import map from 'lodash/map';
import merge from 'lodash/merge';
import range from 'lodash/range';

const AccordionLayoutComponent = (props) => {
	const [expanded, setExpanded] = useState(false);
	const [labelHtmlId] = useState(createId('expand-panel'));

	const handleChange = useCallback(
		(panel) => (_event, expandedPanel) => {
			setExpanded(expandedPanel ? panel : false);
		},
		[]
	);
	const isExpanded = (index) => expanded === composePaths(props.path, `${index}`);

	const { enabled, data, path, schema, uischema, renderers, cells, rootSchema, config, uischemas } = props;

	const appliedUiSchemaOptions = merge({}, config, props.uischema.options);

	return (
		<div>
			{data ? (
				data.map((child, index) => {
					return (
						<Accordion aria-labelledby={labelHtmlId} expanded={isExpanded(index)} onChange={handleChange(child.path)}>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<Grid container alignItems={'center'}>
									<Grid item xs={7} md={9}>
										<Grid container alignItems={'center'}>
											<Grid item xs={2} md={1}></Grid>
											<Grid item xs={10} md={11}>
												<span id={labelHtmlId}>{child.name}</span>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</AccordionSummary>
							<AccordionDetails>
								<JsonFormsDispatch
									enabled={enabled}
									schema={schema}
									uischema={uischema}
									path={path}
									key={path}
									renderers={renderers}
									cells={cells}
								/>
							</AccordionDetails>
						</Accordion>
						// <ChildLayoutRenderer
						// 	enabled={enabled}
						// 	index={index}
						// 	expanded={isExpanded(index)}
						// 	schema={schema}
						// 	path={path}
						// 	handleExpansion={handleChange}
						// 	uischema={uischema}
						// 	renderers={renderers}
						// 	cells={cells}
						// 	key={index}
						// 	rootSchema={rootSchema}
						// 	enableMoveUp={index != 0}
						// 	enableMoveDown={index < data - 1}
						// 	config={config}
						// 	childLabelProp={appliedUiSchemaOptions.elementLabelProp}
						// 	uischemas={uischemas}
						// />
					);
				})
			) : (
				<p>No data</p>
			)}
		</div>
	);
};

export const AccordionLayoutRenderer = {
	tester: rankWith(2000, scopeEndsWith('landingPages')),
	renderer: withVanillaControlProps(withJsonFormsControlProps(AccordionLayoutComponent)),
};
