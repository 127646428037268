import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { showModalForm } from '../../actions/modalForm';
import { setIntegrations } from '../../actions/integrations';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import Api from '../../api/api';
import ToggleSwitch from './ToggleSwitch';
import ModalFormBasedAuth from './ModalFormBasedAuth';
import ModalConfig from './ModalConfig';
import { toast } from "react-toastify";

function IntegrationHorizontal(props) {
	const { name, description, icon } = props.data.display;
	const { type, status: initialStatus } = props.data;


	const [isProcessing, setIsProcessing] = useState(false);
	const [status, setStatus] = useState(initialStatus);
	const [jsonSchema, setJsonSchema] = useState({});
	const [uiSchema, setUiSchema] = useState({});
	const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

	const api = new Api();

	const getAuthorizeRequirements = async (e) => {
                e.stopPropagation();
		setIsProcessing(true);
		api.setJwt(sessionStorage.getItem('jwt'));
		const authorizeData = await api.getAuthorizeRequirements(type, '');
		if (authorizeData.type === 'oauth2') {
			window.location.href = authorizeData.url;
		}
		if (authorizeData.type !== 'oauth2') {
			let data = authorizeData.data
			for (const element of Object.entries(data.uiSchema)) {
				if (!element['ui:widget']) {
					element['ui:widget'] = 'text';
				}
			}
			setJsonSchema(data.jsonSchema);
			setUiSchema(data.uiSchema);
			openAuthModal();
		}
	};

	function openAuthModal() {
		setIsAuthModalOpen(true);
	}
	function closeAuthModal() {
		setIsAuthModalOpen(false);
		setIsProcessing(false);
	}

	const disconnectIntegration = async () => {
		const jwt = sessionStorage.getItem('jwt');
		api.setJwt(jwt)

		try {
			await api.deleteIntegration(props.data.id);
			setIsProcessing(true);
			setStatus(false)
			toast.success('Integration successfully disconnected!')
		} catch (e) {
			toast.error('Oops, something went wrong. Please try again.');
		}

		await refreshIntegrations();
		setIsProcessing(false);
	};
	const refreshIntegrations = props.refreshIntegrations;
	const imgComp = <img className="mr-3 w-[80px] h-[80px] rounded-lg" alt={name} src={icon} />
	const nameAndDescription = (
		<>
			<p className="w-full text-lg font-bold truncate ...">
				{name}
			</p>
			<p className="pt-2 text-sm font-medium text-gray-600">{description}</p>
		</>
	);

        const handleNavigateClick = status ? () => props.history.push({
          pathname: `/integrations/${name}`,
          state: { integration: props.data }
        }) : () => {};

	return (
		<>
                        <div className={'flex flex-nowrap p-4 bg-white rounded-lg shadow-xs' + (status && ' cursor-pointer')}
                             onClick={handleNavigateClick}>
                                {imgComp}
				<div className="pr-1 overflow-hidden">
                                        {nameAndDescription}
					{status && status === 'NEEDS_CONFIG' && (
						<p className="inline-flex pt-2 text-xs font-medium text-red-300">
							<ExclamationCircleIcon className="w-4 h-4 mr-1" /> Configure
						</p>
					)}
				</div>
				<div className="ml-auto">
					<div className="relative">
						{status && (
							<ToggleSwitch
								disconnectIntegration={disconnectIntegration}
							/>
						)}
						{!status && (
							<button
								onClick={getAuthorizeRequirements}
								className="px-3 py-2 text-xs font-medium leading-5 text-center text-white transition-colors duration-150 bg-unbabel-blue-normal border border-transparent rounded-lg active:bg-unbabel-blue-normal hover:bg-unbabel-blue-dark focus:outline-none focus:shadow-outline-unbabel-blue-normal"
							>
								{isProcessing ? (
									<svg className="animate-spin h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
										<circle
											className="opacity-25"
											cx="12"
											cy="12"
											r="10"
											stroke="currentColor"
											strokeWidth="4"
										></circle>
										<path
											className="opacity-75"
											fill="currentColor"
											d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
										></path>
									</svg>
								) : (
									'Connect'
								)}
							</button>
						)}
					</div>
				</div>
			</div>

			{isAuthModalOpen ? (
				<ModalFormBasedAuth
					isAuthModalOpen={isAuthModalOpen}
					closeAuthModal={closeAuthModal}
					refreshIntegrations={refreshIntegrations}
					jsonSchema={jsonSchema}
					uiSchema={uiSchema}
					name={name}
					type={type}
					setStatus={setStatus}
					setIsProcessing={setIsProcessing}
				></ModalFormBasedAuth>
			) : null}

		</>
	);
}

function mapStateToProps({ auth, integrations }) {
	return {
		authToken: auth.token,
		integrations,
	};
}

export default withRouter(connect(mapStateToProps)(IntegrationHorizontal));
