import React, {useEffect, useState} from 'react';
import "react-chat-elements/dist/main.css"
import {MessageList, Input, Button, Avatar} from "react-chat-elements"
import Select from 'react-select'
import Api from '../../api/api'

const inputReferance = React.createRef();
const sourceReferance = React.createRef();
const targetReferance = React.createRef();



function UnbabelChat({integration, authToken}) {
    const api = new Api();
    api.setJwt(authToken);
    const [dropdown, setDropdown] = useState(false);
    const [messageInput, setMessageInput] = useState({});
    const [messages, setMessages] = useState([]);
    const [messageResponse, setMessageResponse] = useState(null);
    const [sourceLanguage, setSourceLanguage] = useState(null);
    const [targetLanguage, setTargetLanguage] = useState(null);
    const [primaryIsSelected, setPrimaryIsSelected] = useState(false);
    const [sourceLanguageOptions, setSourceLanguageOptions] = useState([]);
    const [targetLanguageOptions, setTargetLanguageOptions] = useState([]);
    const [targetLanguageOptionsDictionary, setTargetLanguageOptionsDictionary] = useState({});
    const [chatId, setChatId] = useState(Math.floor(Math.random() * 1000000000));

    useEffect(async () => {
        const api = new Api();
        api.setJwt(authToken);
        const languagePairs = await api.getLanguagePairs(integration.id);
        const targetLanguageDictionary = {};
        const sourceLanguageMap = new Map();
        setMessages([
            {
                avatar: 'https://friggframework.org/assets/img/favicon.svg',
                size: 'xlarge',
                position: "left",
                type: "text",
                title: "Input",
                text: "Aloha! Let's get started!",
            }
        ]);

        for (const languagePair of languagePairs.objects) {
            const sourceLanguage = languagePair.lang_pair.source_language;
            const targetLanguage = languagePair.lang_pair.target_language;
            !(sourceLanguage.shortname in targetLanguageDictionary) && (targetLanguageDictionary[sourceLanguage.shortname] = []);
            targetLanguageDictionary[sourceLanguage.shortname].push({
                value: targetLanguage.shortname,
                label: targetLanguage.name,
            })
            sourceLanguageMap.set(sourceLanguage.shortname, {
                value: sourceLanguage.shortname,
                label: sourceLanguage.name,
            })

        }
        const sourceLanguageOptions = Array.from(sourceLanguageMap.values());
        setSourceLanguageOptions(sourceLanguageOptions);
        setTargetLanguageOptionsDictionary(targetLanguageDictionary)

    }, [])
    const targetLanguagePicker = (eh) => {
        setSourceLanguage(eh.value)
        const availableTargets = targetLanguageOptionsDictionary[eh.value]
        setTargetLanguageOptions(availableTargets)
        setPrimaryIsSelected(true)
    }
    const targetLanguagePicked = (eh) => {
        setTargetLanguage(eh.value)
    }
    const sendMessage = async (e) => {
        e.preventDefault();
        const message = inputReferance.current.value;
        const messageId = Math.floor(Math.random() * 100000);
        setMessageInput({message, messageId})
        if (message) {
            setMessages( [...messages, {
                avatar: 'https://friggframework.org/assets/img/favicon.svg',
                size: 'xlarge',
                position: "left",
                type: "text",
                title: "Input",
                text: message,
            },
                {
                    avatar: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Logo_unbabel_vertical_pos.png/800px-Logo_unbabel_vertical_pos.png',
                    size: 'xlarge',
                    position: "right",
                    type: "text",
                    title: "Translation",
                    text: "(Translating... please wait)"
                }]);
            inputReferance.current.value = "";
        }


        let messageRes = await api.sendChatMessage(integration.id, {
            text: message,
            text_format: "text",
            external_id: messageId,
            chat_external_id: chatId,
            source_language: sourceLanguage,
            target_language: targetLanguage,
        });
        setMessageResponse(messageRes)

    }
    useEffect(async () => {
        if(messageResponse) {
            if (messageResponse.message.status !== 'mt_done') {
                // wait half a second
                await new Promise(r => setTimeout(r, 500));
                setMessageResponse(await api.sendChatMessage(integration.id, {
                    text: messageInput.message,
                    text_format: "text",
                    external_id: messageInput.messageId,
                    chat_external_id: chatId,
                    source_language: sourceLanguage,
                    target_language: targetLanguage,
                }));
            }
            if (messageResponse.message.status === 'mt_done') {
                setMessages([...(messages.slice(0,-1)),{
                    avatar: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Logo_unbabel_vertical_pos.png/800px-Logo_unbabel_vertical_pos.png',
                    size: 'xlarge',
                    position: "right",
                    type: "text",
                    title: "Translation",
                    text: messageResponse.message.translated_text,
                }] );
                setMessageResponse(null);
            }
        }

    }, [messageResponse])

    return (
        <>
            <div className="chat-widget">

                <div className="chat-widget__body">
                    <MessageList
                        className='message-list'
                        lockable={true}
                        toBottomHeight={'100%'}
                        dataSource={messages}
                    />
                    <form
                        onSubmit={sendMessage}>
                    <Input
                        referance={inputReferance}
                        placeholder='Type here...'
                        multiline={true}

                        rightButtons={<Button color='white' backgroundColor='black' text='Send'/>}
                    />
                        <label for={'source-language-select'} >Source Language: </label> <Select id={'source-language-select'} isSearchable={true} options={sourceLanguageOptions} onChange={targetLanguagePicker}/>
                        {primaryIsSelected &&
                            <><label htmlFor={'target-language-select'}>Target Language: </label><Select isSearchable={true}  options={targetLanguageOptions} onChange={targetLanguagePicked}/></>
                        }
                    </form>
                </div>
            </div>

        </>
    );
}
export default UnbabelChat;
