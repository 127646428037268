import { withJsonFormsControlProps } from "@jsonforms/react";
import { rankWith, scopeEndsWith } from "@jsonforms/core";
import { withVanillaControlProps } from "@jsonforms/vanilla-renderers";

import { Switch } from "../ui/Switch";

const SwitchComponent = ({ data, handleChange, path, label, description }) => {
  return (
    <div className="mb-[40px]">
      <Switch
        value={data}
        updateValue={(newValue) => handleChange(path, newValue)}
        label={label}
        description={"Once a page is translated, publish it immediately "}
      />
    </div>
  );
};

export const SwitchControl = {
  tester: rankWith(2000, scopeEndsWith("autoPublish")),
  renderer: withVanillaControlProps(withJsonFormsControlProps(SwitchComponent)),
};
