import { withJsonFormsControlProps } from '@jsonforms/react';
import { rankWith, scopeEndsWith, and } from '@jsonforms/core';
import { withVanillaControlProps } from '@jsonforms/vanilla-renderers';

import { Checkbox } from '../ui/Checkbox';

const CheckboxComponent = ({ data, handleChange, path }) => (
	<Checkbox value={data} updateValue={(newValue) => handleChange(path, newValue)} />
);

export const CheckboxControl = {
	tester: rankWith(2000, scopeEndsWith('sendForTranslation')),
	renderer: withVanillaControlProps(withJsonFormsControlProps(CheckboxComponent)),
};
