import { withJsonFormsControlProps } from '@jsonforms/react';
import { rankWith, scopeEndsWith, and } from '@jsonforms/core';
import { withVanillaControlProps } from '@jsonforms/vanilla-renderers';

const NameComponent = ({ data, ...props }) => <div className="text-[#8D98AA]">{data}</div>;

export const NameControl = {
	tester: rankWith(2000, scopeEndsWith('name')),
	renderer: withVanillaControlProps(withJsonFormsControlProps(NameComponent)),
};
