import { withJsonFormsControlProps } from '@jsonforms/react';
import { rankWith, scopeEndsWith, and } from '@jsonforms/core';
import { withVanillaControlProps } from '@jsonforms/vanilla-renderers';

import { Status } from '../ui/Status';

const StatusComponent = ({ data, animated = false }) => <Status animated={animated}>{data}</Status>;

export const StatusControl = {
	tester: rankWith(2000, scopeEndsWith('status')),
	renderer: withVanillaControlProps(withJsonFormsControlProps(StatusComponent)),
};
