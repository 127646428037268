import { useState } from "react";
import { formatDistanceToNow, format } from "date-fns";
import "./table.css"

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import {rankWith, scopeEndsWith} from "@jsonforms/core";

const renderValue = ({ value, settings }) => {
  let renderedVal = value;
  if (settings.type === "date") {
    if (settings.format === "time-ago") {
      renderedVal = value ?
        formatDistanceToNow(new Date(value), { addSuffix: true }) :
        '-';
    } else if (settings.format === "iso-format") {
      renderedVal = value ?
        new Date(value).toISOString() :
        '-';
    } else if (settings.format === 'us') {
      renderedVal = value ?
        format(new Date(value), 'hh:mm:ss a, MM/dd/yyyy') :
        '-';
    }
  }

  const props = {};
  if (settings.capitalize) {
    props.textTransform = "capitalize";
  }

  return <Typography {...props}>{renderedVal}</Typography>;
};

const UHeader = ({ column }) => {
  return (
    <TableCell>
      <Typography>{column.label}</Typography>
    </TableCell>
  );
};

const URow = ({ columns, schema, row, isNested, columnRenderers, parentRecord }) => {
  const [open, setOpen] = useState(false);
  const columnsFields = columns.map(col => col.field);
  const childrenKey = Object.keys(schema).find(
    (key) => schema[key].as === "children"
  );
  const isDisabled = row.disabled;
  const disabledStyle = isDisabled ?
    { opacity: 0.5, cursor: "not-allowed" } : {};

  const primativeKeys = (childrenKey
    ? Object.keys(schema).filter(
        (key) => !childrenKey !== key && schema[key].type !== "object"
      )
    : Object.keys(schema)).filter(key => columnsFields.includes(key));

  return (
    <>
      <TableRow sx={disabledStyle}>
        {primativeKeys.map((key, index) => (
          <TableCell key={`cell-${key}`} align="left">
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: isNested && index === 0 ? 10 : 0,
              }}
            >
              {index === 0 && childrenKey ? (
                <IconButton
                  sx={{ marginRight: 2 }}
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              ) : null}
              {schema[key].selectable ? <Checkbox className="checkbox-container" /> : null}
              <Typography>
                {columnRenderers[key]
                  ? columnRenderers[key](row[key], row, isNested, parentRecord)
                  : renderValue({ value: row[key], settings: schema[key] })}
              </Typography>
            </Box>
          </TableCell>
        ))}
      </TableRow>
      {childrenKey && open ? (
        <>
          {row[childrenKey].map((nestedRow) => {
            return (
              <URow
                key={`${nestedRow.id}-${childrenKey}`}
                schema={schema[childrenKey].properties}
                columns={columns}
                row={nestedRow}
                isNested={true}
                parentRecord={row}
                columnRenderers={columnRenderers}
              />
            );
          })}
        </>
      ) : null}
    </>
  );
};

const UTable = ({
  loading = false,
  schema = {},
  data = [],
  columnRenderers = {},
  uischema = { loading: false, data: [], columnRenderers: {} },
}) => {
  const dataMap = uischema ? uischema.data : data
  const columnSchema = uischema ? uischema.columnRenderers : columnRenderers
  const loadingState = uischema ? uischema.loading : loading

  return (
    <div>
      <TableContainer component={Paper} className="table-container">
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {schema?.columns?.map((column) => (
              <UHeader column={column} />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loadingState ? (
            <TableRow>
              <TableCell colSpan={schema?.columns?.length || 1}>
                <div className="flex justify-center align-center w-full">
                  <div>
                    <CircularProgress />
                  </div>
                </div>
              </TableCell>
            </TableRow>
          ) : (
            dataMap.map((row) => (
              <URow
                columns={schema.columns}
                key={row.id}
                row={row}
                schema={schema.schema.properties}
                columnRenderers={columnSchema}
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
};

export default UTable;

export const uTableTester = rankWith(
  5,
  scopeEndsWith('parentRecords')
);
