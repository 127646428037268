import React, { useState, useEffect, useMemo } from "react";
import {materialCells, materialRenderers} from "@jsonforms/material-renderers";
import API from "../../api/api";
import UTable, {uTableTester} from "../custom/Table/Table";
import {
  Checkbox,
  Chip,
  IconButton,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import { RefreshIcon, SearchIcon } from "@heroicons/react/outline";
import { toast } from "react-toastify";
import useEvent from "../../hooks/useEvent";
import LANGUAGE_CODES from "../../constants/languageCodes";
import { format } from "date-fns";
import "./unbabel.css"
import {JsonForms} from "@jsonforms/react";
import UnbabelFooter from "./UnbabelFooter";

const CheckBoxComponent = (props) => {
  const {
    value,
    row,
    isNested,
    parent,
    data,
    setData,
  } = props;

  const isDisabled = row.disabled

  const disabledMessage = row.disabledMessage

  const [isChecked, setIsChecked] = useState(false);

  const areAllChecked = useMemo(
    () =>
      !isNested &&
      row.languageVariants.every((translation) => !!translation.sendForTranslation),
    [isNested, row.languageVariants]
  );

  useEffect(() => {
    setIsChecked(isNested ? !!row.sendForTranslation : areAllChecked);
  }, [isNested, row.sendForTranslation, areAllChecked]);

  const isIndeterminate = () => {
    if (isNested) return false;

    const atLeastOneChecked = row.languageVariants.some(
      (translation) => !!translation.sendForTranslation
    );

    return atLeastOneChecked && !areAllChecked;
  };

  const onCheckChange = (newCheck) => {
    const updatedData = { ...data };

    if (!isNested) {
      const parentRowIndex = updatedData.parentRecords.findIndex(
        (item) => item.id === row.id
      );

      if (parentRowIndex !== -1) {
        updatedData.parentRecords[parentRowIndex].languageVariants = updatedData.parentRecords[
          parentRowIndex
          ].languageVariants.map((variant) => ({
          ...variant,
          sendForTranslation:  variant.pipelineAvailable ? newCheck : false,
        }));
      }
    } else {
      const parentRowIndex = updatedData.parentRecords.findIndex(
        (item) => item.slug ? item.slug === parent.slug : item.id === parent.id
      );

      const languageVariantIndex = updatedData.parentRecords[
        parentRowIndex
        ].languageVariants.findIndex(
        (variant) => variant.slug ? variant.slug === row.slug : variant.language === row.language
      );

      updatedData.parentRecords[parentRowIndex].languageVariants[
        languageVariantIndex
        ].sendForTranslation = newCheck;
    }

    setData(updatedData);
    setIsChecked(newCheck);
  };

  return (
    <>
      {isDisabled ? (
        <Tooltip title={disabledMessage}>
          <Checkbox
            disableRipple
            checked={false}
            style={{ opacity: 0.5, cursor: 'not-allowed' }}
          />
        </Tooltip>
      ) : (
        <Checkbox
          checked={isChecked}
          indeterminate={isIndeterminate()}
          onChange={(e) => onCheckChange(e.target.checked)}
        />
      )}
      {isNested ? '' : value}
    </>
  );
};

const UnbabelAction = ({ integrationId, actionId }) => {
  const [search, setSearch] = useState("");
  const [language, setLanguage] = useState("");
  const [publishingState, setPublishingState] = useState("");

  const [integration, setIntegration] = useState(null);
  const [data, setData] = useState({});
  const [jsonSchema, setJsonSchema] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [lastRefreshedAt, setLastRefreshedAt] = useState(
    format(new Date(), "h:mm aaa")
  );
  const { subscribe, unsubscribe, emit } = useEvent({
    name: `INTEGRATION_UPDATED_${integrationId}`,
  });

  useEffect(() => {
    function getData() {
      getIntegration();
      getIntegrationActionOptions();
    }

    const onIntegrationChange = async () => {
      getData();
    };
    const id = subscribe(onIntegrationChange);

    getData();
    return () => {
      unsubscribe(id);
    };
  }, [integrationId, actionId]);

  const getIntegrationActionOptions = async () => {
    const jwt = sessionStorage.getItem("jwt");
    const api = new API();
    if (jwt) {
      api.setJwt(jwt);
      const { jsonSchema, uiSchema, data } =
        await api.getIntegrationActionOptions(integrationId, actionId);
      setJsonSchema(jsonSchema);
      setData(data);
      setLoading(false);
      setLastRefreshedAt(format(new Date(), "h:mm aaa"));
    }
  };

  const getIntegration = async () => {
    const jwt = sessionStorage.getItem("jwt");
    const api = new API();
    if (jwt) {
      api.setJwt(jwt);
      const integration = await api.getIntegration(integrationId);
      setIntegration(integration);
    }
  };

  const onRefresh = () => {
    setLoading(true);
    getIntegrationActionOptions();
    getIntegration();
  };

  const submit = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);

    const jwt = sessionStorage.getItem("jwt");
    const api = new API();
    if (jwt) {
      api.setJwt(jwt);

      try {
        await api.createIntegrationAction(integrationId, actionId, data);
        toast.success("Translation started successfully!");
        setButtonDisabled(false);
        emit();
      } catch (e) {
        toast.error("Oops, something went wrong. Please try again.");
      }
    }
  };

  const columnRenderers = {
    pageStatus: (value) => {
      if (value === "draft") {
        return (
          <Chip
            style={{
              color: "#C34303",
              backgroundColor: "#FFF1EB",
              textTransform: "capitalize",
            }}
            label={value}
          />
        );
      } else if (value === "published") {
        return (
          <Chip
            style={{
              color: "#3843D0",
              backgroundColor: "#F2F3FC",
              textTransform: "capitalize",
            }}
            label={value}
          />
        );
      } else if (value) {
        return (
          <Chip
            label={(value || "").split("_").join(" ")}
            className="capitalize"
          />
        );
      } else {
        return null;
      }

    },
    language: (value) => {
      if (!value) return "";
      const code = String(value).substring(0, 2).toLowerCase();
      return LANGUAGE_CODES[value.toLowerCase()]?.name || LANGUAGE_CODES[code]?.name || "";
    },
    name: (value, row, isNested, parent) =>
      <CheckBoxComponent
        value={value}
        row={row}
        isNested={isNested}
        parent={parent}
        data={data}
        setData={setData}
      />,
    translationStatus: (value) => {
      if (value === "in_progress") {
        return (
          <Chip
            style={{
              color: "#9152ae",
              backgroundColor: "#f7edfc",
              textTransform: "capitalize",
            }}
            label="Translating"
          />
        );
      } else if (value === "completed") {
        return (
          <Chip
            style={{
              color: "white",
              backgroundColor: "#9152ae",
              textTransform: "capitalize",
            }}
            label="Translated"
          />
        );
      } else if (value) {
        return (
          <Chip
            label={(value || "").split("_").join(" ")}
            className="capitalize"
          />
        );
      } else {
        return null;
      }
    },
  };

  const allVariants = useMemo(() => {
      return data?.parentRecords
        ?.reduce((all, record) => all.concat(record.languageVariants), [])
  }, [data]);
  const selectedPages = useMemo(
    () =>
      allVariants?.filter((variation) => variation.sendForTranslation) || [],
    [allVariants]
  );

  const allLanguagesCodes = useMemo(() => {
    if (!data?.parentRecords?.length) return [];
    const codes = [];
    data.parentRecords.forEach(record => {
      if (record.language && !codes.includes(record.language.toLowerCase())) {
        codes.push(record.language.toLowerCase());
      }
      record.languageVariants.forEach(variant => {
        const code = variant.language.toLowerCase();
        if (!codes.includes(code)) {
          codes.push(code);
        }
      })
    })

    return codes;
  }, [data?.parentRecords])
  const filteredItems = useMemo(() => {
    let items = data?.parentRecords || [];
    if (!items.length) return [];

    if (search) {
      items = items.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (language) {
      items = items.filter(
        (item) => (item.language && item.language.toLowerCase() === language) ||
          item.languageVariants
            .map((variant) => variant.language.toLowerCase())
            .some((lang) => lang === language)
      );
    }

    if (publishingState) {
      items = items
        .filter((item) =>
          item.languageVariants.some(
            (variation) => variation.pageStatus === publishingState
          )
        )
        .map((item) => {
          const languageVariants = item.languageVariants.filter(
            (variation) => variation.pageStatus === publishingState
          );
          return {
            ...item,
            languageVariants,
          };
        });
    }

    // Sort alphabetically before returning list
    return items.sort((a, b) => (a.name > b.name) ? 1 : -1);
  }, [data?.parentRecords, search, language, publishingState]);

  const publishStatusOptions = useMemo(() => {
    const options = [];
    const optionsKeys = [];
    data?.parentRecords?.forEach((item) => {
      if (!optionsKeys.includes(item.pageStatus)) {
        optionsKeys.push(item.pageStatus);
        options.push({
          label: item.pageStatus?.replace(/\_/g, " "),
          value: item.pageStatus,
        });
      }
    });

    return options;
  }, [data?.parentRecords]);

  const customUiSchema = {
    type: 'UTable',
    loading: loading,
    columnRenderers: columnRenderers,
    data: filteredItems || [],
    scope: '#/properties/parentRecords',
  };

  const renderers = [
    ...materialRenderers,
    { tester: uTableTester, renderer: UTable },
  ];

  return (
    <>
      {
        <>
          <div className="flex justify-end items-center px-3">
            <div className="flex items-center">
              Last refreshed: {lastRefreshedAt}
            </div>
            <Tooltip title="Refresh table content">
              <IconButton onClick={onRefresh}>
                <RefreshIcon className="w-[25px] h-[25px]" />
              </IconButton>
            </Tooltip>
          </div>
          <div className="flex justify-between items-center my-2 px-3">
            <div>
              <select
                value={publishingState}
                onChange={(e) => setPublishingState(e.target.value)}
                className="bg-transparent rounded-full py-1 pr-1 pl-3 mr-4 text-sm	text-[#343941]"
                style={{ border: "1px solid #dee3ed" }}
              >
                <option value="">State of publishing</option>
                {publishStatusOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <select
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                className="bg-transparent rounded-full py-1 pr-1 pl-3 text-sm	text-[#343941]"
                style={{ border: "1px solid #dee3ed" }}
              >
                <option value="">Page language</option>
                {allLanguagesCodes.map((code) => (
                  <option key={code} value={code}>
                    {LANGUAGE_CODES[code]?.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-[30%]">
              <OutlinedInput
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                fullWidth
                endAdornment={<SearchIcon color="#343941" className="w-5 h-5" />}
                className="select-field"
                sx={{ borderRadius: 10 }}
                placeholder="Search page title"
              />
            </div>
          </div>
          <JsonForms
            schema={jsonSchema}
            uischema={customUiSchema}
            data={filteredItems || []}
            renderers={renderers}
            cells={materialCells}
          />
          <UnbabelFooter
            loading={loading}
            integration={integration}
            hasVariants={allVariants?.length > 0}
            selectedPages={selectedPages}
            buttonDisabled={buttonDisabled}
            onSubmit={submit}
          />
        </>
      }
    </>
  );
};

export const Type = "unbabel";

export default UnbabelAction;
