import { InfoCircledIcon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";

const UnbabelFooter = ({
  selectedPages,
  integration,
  loading,
  hasVariants,
  buttonDisabled,
  onSubmit,
}) => {
  const [noteVisible, setNoteVisible] = useState(false);

  useEffect(() => {
    if (!integration?.id || loading) return;
    const noticeDisplayedKey = `unbabel_note_was_displayed_${integration.id}`;
    const noticeDisplayed = window.localStorage.getItem(noticeDisplayedKey);

    if (!hasVariants) {
      window.localStorage.setItem(noticeDisplayedKey, "true");
      return setNoteVisible(true);
    }

    if (!noticeDisplayed) {
      window.localStorage.setItem(noticeDisplayedKey, "true");
      return setNoteVisible(true);
    }

    setNoteVisible(false);
  }, [integration?.id, hasVariants, loading]);

  return (
    <div className="fixed bottom-0 right-0 left-0 p-[8px] pl-[16px] bg-[#040237] flex justify-between items-center text-white z-20">
      <div className="flex items-center">
        {noteVisible ? (
          <>
            <InfoCircledIcon className="w-5 h-5 mr-2" />
            Remember you{" "}
            <span className="mx-1 text-orange-500">need to have variants </span>
            in{" "}
            <span className="capitalize mx-1">
              {integration?.config?.type}
            </span>{" "}
            already created for the option to translate
          </>
        ) : null}
      </div>
      <div className="flex items-center justify-end">
        <p className="mr-10">{selectedPages?.length} pages selected</p>
        <button
          onClick={onSubmit}
          disabled={!selectedPages?.length || buttonDisabled}
          className={`bg-[#eee] px-[16px] py-[6px] text-black rounded-[4px] ${
            !selectedPages?.length || buttonDisabled ? "opacity-80" : ""
          }`}
        >
          Translate
        </button>
      </div>
    </div>
  );
};

export default UnbabelFooter;
