import React, { ComponentType } from "react";
import { LayoutProps } from "@jsonforms/core";
import {

    JsonFormsStateContext,
    ResolvedJsonFormsDispatch,
    withJsonFormsContext,
} from "@jsonforms/react";

import Card from "./Card";

export const LanguageVariantRenderer = (props) => {
    const { uischema, schema, path, renderers, cells, onRemove } = props;
    const elements = uischema.options?.["detail"]["elements"];
    const itemsToRender = elements.map((element, index) => {
        return (
            <ResolvedJsonFormsDispatch
                schema={schema}
                uischema={element}
                path={path}
                enabled={true}
                renderers={renderers}
                cells={cells}
                key={index}
            />
        );
    });
    return (
        <Card>
            {itemsToRender}
        </Card>
    );
};

const withContextToCardRendered = (Component)=> ({ ctx, props }) => {
    return <Component {...props}/>;
};

// const withCustomProps = (Component) => {
//     return withJsonFormsContext(
//         withContextToCardRendered(
//             React.memo(Component, (prevProps, props) => areEqual(prevProps, props))
//         )
//     );
// };
//
// export default withCustomProps(LanguageVariantRenderer);
