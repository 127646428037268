import { withJsonFormsControlProps } from "@jsonforms/react";
import { rankWith, scopeEndsWith } from "@jsonforms/core";
import { withVanillaControlProps } from "@jsonforms/vanilla-renderers";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useMemo } from "react";

export const SelectComponentSpaces = ({
  data,
  handleChange,
  label,
  schema,
  path,
  rootSchema,
}) => {
  return (
    <div style={{ marginTop: '15px' }}>
      <p className="text-[12px] mb-[8px]" style={{ color: "#696C72" }}>
        Select a space
      </p>
      <Select
        className="mb-[8px] w-80"
        value={data}
        onChange={(e) => handleChange(path, e.target.value)}
        placeholder={label}
      >
        {schema.oneOf.map((item) => (
          <MenuItem key={item.const} value={item.const}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export const SelectControlSpaces = {
  tester: rankWith(2000, scopeEndsWith("spaceId")),
  renderer: withVanillaControlProps(withJsonFormsControlProps(SelectComponentSpaces)),
};
