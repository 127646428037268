import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';

import { Button } from '../ui/Button';
import API from '../../api/api';

const useStyles = makeStyles({
    textarea: {
        width: '100%',
        height: '30em',
        border: '1px solid #000',
    },
    spacer: {
        padding: '1em 0',
    }
});

const WorkflowDataArea = ({ integrationId, extensionId }) => {
    const classes = useStyles();
    const [workflowData, setWorkflowData] = useState(undefined);
    const [errorState, setErrorState] = useState(false);

    const api = new API();
    api.setJwt(sessionStorage.getItem('jwt'));

    const workflowEventTypes = {
        clear: 'clear-workflow-data',
        request: 'request-workflow-data',
    };

    const sendExtensionRequest = (messageType) => {
        setErrorState(false);
        setWorkflowData(undefined);
        chrome.runtime.sendMessage(extensionId, {
            type: messageType,
            targetUrl: `${api.baseURL}${api.endpointIntegration(integrationId)}`,
            requestMethod: 'PATCH',
            authorizationToken: api.jwt,
        }, {}, ({ result }) => {
            setWorkflowData(result.config.workflows ?? []);
        });
    }

    useEffect(() => {
        api.getIntegrationConfigOptions(integrationId)
            .then((config) => {
                setWorkflowData(config.workflows);
            })
            .catch(() => {
                setErrorState(true);
            });
    }, []);

    return (
        <>
            {!workflowData && <p>Loading data, please wait...</p>}
            {workflowData?.length === 0 && <Button onClick={() => sendExtensionRequest(workflowEventTypes.request)}>Obtain Workflow Data</Button>}
            {workflowData?.length > 0 && 
                <>
                    <p>Data has been retrieved</p>
                    <p className={classes.spacer}>
                        <Button onClick={() => sendExtensionRequest(workflowEventTypes.clear)}>Clear Data</Button>
                    </p>
                </>
            }
            {errorState && <p>Error loading extension configuration.</p>}
        </>
    );
};

export default WorkflowDataArea;
