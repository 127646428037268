import ChromeExtensionAction, { Type as ChromeExtensionActionType } from '../components/chrome-extension/Action';
import UnbabelAction, { Type as UnbabelActionType } from '../components/Unbabel/UnbabelAction';

export const ActionMapper = {
    [ChromeExtensionActionType]: ChromeExtensionAction,
    [UnbabelActionType]: UnbabelAction,
    get defaultAction() {
        return UnbabelAction;
    }
};
