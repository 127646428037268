import { Fragment, useState, useMemo } from 'react';
import { JsonForms } from '@jsonforms/react';
import Grid from '@mui/material/Grid';
import ButtonMUI from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import schemaUI from '../schemas/UI/schemaUI.json';
import uischemaUI from '../schemas/UI/uischemaUI.json';
import data from '../schemas/UI/data.json';

import { vanillaCells, vanillaRenderers, JsonFormsStyleContext } from '@jsonforms/vanilla-renderers';
import { materialCells, materialRenderers } from '@jsonforms/material-renderers';
import { CheckboxControl } from '../components/controls/CheckboxControl';
import { InputControl } from '../components/controls/InputControl';
import { LastModifiedControl } from '../components/controls/LastModifiedControl';
import { LocaleControl } from '../components/controls/LocaleControl';
import { StatusControl } from '../components/controls/StatusControl';
import { NameControl } from '../components/controls/NameControl';
import LanguageVariantsRenderer, { languageVariantsTester } from '../components/custom/LanguageVariantsRenderer';

import { makeStyles } from '@mui/styles';

import AccordionGroupRenderer, { accordionGroupRenderer } from '../components/layouts/AccordionGroup';
import { AccordionLayoutRenderer } from '../components/layouts/AccordionLayoutRenderer';

import { FriggLayoutRenderer } from '../components/custom/FriggLayoutRenderer';

const useStyles = makeStyles({
	container: {
		padding: '1em',
		width: '100%',
	},
	title: {
		textAlign: 'center',
		padding: '0.25em',
	},
	dataContent: {
		display: 'flex',
		justifyContent: 'center',
		borderRadius: '0.25em',
		backgroundColor: '#cecece',
		marginBottom: '1rem',
	},
	resetButton: {
		margin: 'auto !important',
		display: 'block !important',
	},
	demoform: {
		margin: 'auto',
		padding: '1rem',
	},
});


const dataUI = data;


const renderersUI = [
	...materialRenderers,
	// ...vanillaRenderers,
	//register custom renderers
	CheckboxControl,
	InputControl,
	LastModifiedControl,
	LocaleControl,
	StatusControl,
	NameControl,
	{ tester: languageVariantsTester, renderer: LanguageVariantsRenderer },
	// AccordionLayoutRenderer,
	// { tester: accordionGroupRenderer, renderer: AccordionGroupRenderer },

	// FriggLayoutRenderer,
];

const App = () => {
	const classes = useStyles();
	const [data, setData] = useState({});

	const stringifiedData = useMemo(() => JSON.stringify(data, null, 2), [data]);

	const clearData = () => {
		setData({});
	};
	const submit = () => {
        console.log(data);
    };

	return (
		<Fragment>
			<div className="bg-blue-500">tailwind test</div>
			<ButtonMUI className={classes.submitButton} onClick={submit} color="primary" variant>Submit Data</ButtonMUI>

			<Grid container justifyContent={'center'} spacing={1} className={classes.container}>
				<Grid item sm={6}>
					<Typography variant={'h4'} className={classes.title}>
						Bound data
					</Typography>
					<div className={classes.dataContent}>
						<pre id="boundData">{stringifiedData}</pre>
					</div>
					<ButtonMUI className={classes.resetButton} onClick={clearData} color="primary" variant="contained">
						Clear data
					</ButtonMUI>
				</Grid>
			</Grid>

			<div className="bg-blue-500 my-4">tailwind test</div>
			<div className="p-16">
				{/* <JsonFormsStyleContext.Provider value={styleContextValue}> */}
				<JsonForms
					schema={schemaUI}
					uischema={uischemaUI}
					data={dataUI}
					renderers={renderersUI}
					// cells={vanillaCells}
					cells={materialCells}
					onChange={({ errors, data }) => setData(data)}
				/>
				{/* </JsonFormsStyleContext.Provider> */}
			</div>
		</Fragment>
	);
};

export default App;
