import { withJsonFormsControlProps } from "@jsonforms/react";
import { rankWith, scopeEndsWith } from "@jsonforms/core";
import { withVanillaControlProps } from "@jsonforms/vanilla-renderers";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useMemo } from "react";
import LANGUAGE_CODES from "../../constants/languageCodes";

export const SelectComponent = ({
  data,
  handleChange,
  label,
  schema,
  path,
  rootSchema,
}) => {
  const availableLanguages = useMemo(() => {
    if (!data || !rootSchema?.pipelineGroupLanguageCodes?.[data]) {
      return [];
    }

    return rootSchema.pipelineGroupLanguageCodes[data]
      .map((code) => {
        const shortCode = String(code).substring(0, 2).toLowerCase();
        return LANGUAGE_CODES[code.toLowerCase()]?.name || LANGUAGE_CODES[shortCode]?.name || ''
      })
      .filter((item) => item);
  }, [data, rootSchema?.pipelineGroupLanguageCodes]);

  return (
    <div>
      <p className="text-[12px] mb-[8px]" style={{ color: "#696C72" }}>
        Choose default pipeline group
      </p>
      <Select
        className="mb-[8px] w-80"
        value={data}
        onChange={(e) => handleChange(path, e.target.value)}
        placeholder={label}
      >
        {schema.oneOf.map((item) => (
          <MenuItem key={item.const} value={item.const}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
      {availableLanguages.length > 0 && (
        <p
          className="text-[12px] max-w-xs"
          style={{ color: "#696C72" }}
          data-testid="available-languages"
        >
          Available languages in group: {availableLanguages.join(", ")}
        </p>
      )}
    </div>
  );
};

export const SelectControl = {
  tester: rankWith(2000, scopeEndsWith("pipelineGroup")),
  renderer: withVanillaControlProps(withJsonFormsControlProps(SelectComponent)),
};
