export const Input = ({ value, handleChange, path, placeholder = '' }) => {
	return (
		<input
			className="w-[100px] text-sm form-input rounded h-[32px] mr-[4px]"
			type="text"
			value={value}
			placeholder={placeholder}
			onChange={(e) => handleChange(path, e.target.value === '' ? undefined : e.target.value)}
			style={{paddingRight: '10px', width: '200px'}}
		/>
	);
};
