import { MaterialLayoutRenderer } from '@jsonforms/material-renderers';
// import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';

import { Accordion, AccordionTrigger, AccordionItem, AccordionContent } from '../ui/Accordion';
import { ArrayControlRenderer } from '@jsonforms/vanilla-renderers';

import range from 'lodash/range';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState, useMemo } from 'react';
import {
	withJsonFormsLayoutProps,
	withJsonFormsArrayLayoutProps,
	withJsonFormsArrayControlProps,
	JsonFormsDispatch,
} from '@jsonforms/react';
import { withVanillaControlProps } from '@jsonforms/vanilla-renderers';

import {
	rankWith,
	scopeEndsWith,
	uiTypeIs,
	isObjectArrayWithNesting,
	composePaths,
	findUISchema,
} from '@jsonforms/core';

const FriggArrayComponent = (props) => {
	const { uischema, schema, data, path, visible, renderers, enabled, cells } = props;
	const [value, setValue] = useState(['item-1']);

	// const childUiSchema = useMemo(() => findUISchema(uischemas, schema, uischema.scope, path, undefined, uischema, rootSchema),
	// 	[uischemas, schema, uischema.scope, path, uischema, rootSchema]
	// );

	const layoutProps = {
		elements: uischema.elements,
		uischema: uischema,
		schema: schema,
		data: data,
		path: path,
		visible: visible,
		renderers: renderers,
		enabled: enabled,
		cells: cells,
	};
	return (
		<div>
			{visible && (
				<Accordion className="w-full" type="multiple" value={value} onValueChange={setValue}>
					{data ? (
						data.map((child, index) => {
							const childPath = composePaths(path, `${index}`);
							return (
								<AccordionItem value="item-1">
									<AccordionTrigger primary>{child.name}</AccordionTrigger>
									{child.translations.map((child, index) => (
										<AccordionContent>
											{child.name}
											{/* <JsonFormsDispatch
													schema={schema}
													uischema={child}
													path={childPath}
													key={childPath}
													renderers={renderers}
												/> */}
										</AccordionContent>
									))}
								</AccordionItem>
							);
						})
					) : (
						<p>No data</p>
					)}

					{/* {data.landingPages.map((child, index) => (
							<AccordionItem value="index">
								<AccordionTrigger primary>{child.name}</AccordionTrigger>
								{child.translations.map((child, index) => (
									<AccordionContent>{child.name}</AccordionContent>
								))}
							</AccordionItem>
						))} */}
				</Accordion>
			)}
		</div>
	);
};

// const FriggArrayControl = (props) => {
// 	return (
// 		<FriggArrayControl
// 			{...props}
// 		/>
// 	)
// }

export const FriggLayoutRenderer = {
	tester: rankWith(100, scopeEndsWith('landingPages')),
	renderer: withVanillaControlProps(withJsonFormsArrayControlProps(FriggArrayComponent)),
};
