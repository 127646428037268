import { makeStyles } from '@mui/styles';

import CompatibleArea from './CompatibleArea';
import InstalledArea from './InstalledArea';

const useStyles = makeStyles({
    container: {
        padding: '2em 0',
        width: '100%',
    },
});

const Action = ({ extensionId }) => {
    const classes = useStyles();
    return <div className={classes.container}>
        <CompatibleArea>
            <InstalledArea extensionId={extensionId}>
                <p>The extension is installed (ID: <strong>{extensionId}</strong>).</p>
            </InstalledArea>
        </CompatibleArea>
    </div>;
};

export const ActionType = 'INSTALL_EXTENSION';

export default Action;
