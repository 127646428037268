import { React, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { List } from '../components/Integration';
import { SearchIcon, BellIcon } from '@heroicons/react/outline';

function IntegrationsPage(props) {
	const [integrationType, setIntegrationType] = useState('Recently added');
	const [numberIntegrations, setNumberIntegrations] = useState(0);
	const formatIntegrationString = () => {
		let integrationString = 'integrations';
		if (numberIntegrations === 1) {
			integrationString = 'integration';
		}
		return `${numberIntegrations} ${integrationString}`;
	}

	const categories = [
		{ _id: 1, slug: 'recently-added', name: 'Recently added' },
		{ _id: 2, slug: 'marketing', name: 'Marketing' },
		{ _id: 3, slug: 'sales-and-crm', name: 'Sales & CRM' },
		{ _id: 4, slug: 'commerce', name: 'Commerce' },
		{ _id: 5, slug: 'social', name: 'Social' },
		{ _id: 6, slug: 'productivity', name: 'Productivity' },
		{ _id: 7, slug: 'finance', name: 'Finance' },
		{ _id: 8, slug: 'installed', name: 'Installed' },
	];

	useEffect(() => {
		const jwt = props.authToken || sessionStorage.getItem('jwt');
		if (!jwt) {
			props.history.push('/logout');
		}
	}, []);


	const filterIntegration = (type) => {
		setIntegrationType(type);
	};

	return (
		<main className="h-full pb-16 overflow-y-auto">
			<div className="container px-6 mx-auto grid">
				<h2 className="my-6 mb-2 text-2xl font-bold">Integrations</h2>
				<p className="mb-4">Seamlessly connect with the tools you use everyday</p>

				{/* <div
					className="flex flex-nowrap p-4 shadow-xs w-full bg-white border border-[#DEE3ED] border-solid rounded relative mb-10"
					style={{ boxShadow: ' 0px 1px 10px rgba(68, 75, 85, 0.03), 0px 1px 6px rgba(68, 75, 85, 0.03)' }}
				>
					<div className="flex relative w-full mr-2">
						<input
							className="w-full pl-4 pr-2 text-sm text-gray-700 placeholder-gray-600 border-[#59616E] rounded focus:placeholder-gray-500 focus:bg-white focus:border-green-300 focus:outline-none focus:shadow-outline-green form-input h-[48px]"
							type="text"
							placeholder="Search"
							aria-label="Search"
						/>
						<button className="w-[120px] px-2 py-2 font-medium leading-5 text-center text-[#3843D0] transition-colors duration-150 rounded focus:outline-none">
							Clear
						</button>
						<button className="w-[120px] px-2 py-2 font-medium leading-5 text-center text-white transition-colors duration-150 border border-transparent rounded placeholder:focus:outline-none focus:shadow-outline-unbabel-blue-normal bg-[#3843D0]">
							Search
						</button>
					</div>
				</div> */}
				<div className="flex inline-flex border-b-2 border-[#CECFD1] my-6">
					<h3 className="mb-4 text-xl font-bold">All Categories</h3>
					<span className="text-sm mt-1 ml-4 text-gray-500">{formatIntegrationString()}</span>
				</div>
				<div className="grid mb-8 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3">
					<div className="grid gap-6 sm:col-span-2 sm:grid-cols-2 md:col-span-2 md:grid-cols-2 lg:col-span-3 lg:grid-cols-3 xl:col-span-3 xl:grid-cols-3 2xl:col-span-3 2xl:grid-cols-3 2xl:grid-rows-6">
						<List integrationType={integrationType} setNumberIntegrations={setNumberIntegrations} />
					</div>
				</div>
			</div>
		</main>
	);
}

function mapStateToProps({ auth }) {
	return {
		authToken: auth.token,
	};
}

export default connect(mapStateToProps)(IntegrationsPage);
