import React, { useEffect, useState } from 'react';

import { Button } from '../ui/Button';

const ChromeExtensionCompatibleArea = ({ children }) => {
  const compatibilityStates = { Resolving: 'resolving', Supported: 'supported', Unsupported: 'unsupported' };
  const [chromeBrowserCompabilitityState, setChromeBrowserCompatibilityState] = useState(compatibilityStates.Resolving);

  useEffect(() => {
    setChromeBrowserCompatibilityState(
      typeof(window.chrome) === 'object' ?
      compatibilityStates.Supported :
      compatibilityStates.Unsupported
    )
  });

  return (
    <>
      { chromeBrowserCompabilitityState === compatibilityStates.Supported && children }
      { chromeBrowserCompabilitityState === compatibilityStates.Unsupported &&
        (<Button
          disabled={true}
          style={{ cursor: 'not-allowed' }}
          title="To install the extension, please open this page in Google Chrome">
            Chrome-only Extension
        </Button>) }
    </>
  )
};

export default ChromeExtensionCompatibleArea;
