import get from 'lodash/get';
import React, { useMemo, useState, useEffect } from 'react';
import { JsonFormsDispatch, withJsonFormsContext } from '@jsonforms/react';
import { composePaths, findUISchema, Resolve, getFirstPrimitiveProp, createId, removeId } from '@jsonforms/core';
import { Accordion, AccordionSummary, AccordionDetails, Avatar, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ChildRendererComponent = (props) => {
	const [labelHtmlId] = useState(createId('expand-panel'));

	useEffect(() => {
		return () => {
			removeId(labelHtmlId);
		};
	}, [labelHtmlId]);

	const {
		enabled,
		childLabel,
		childPath,
		index,
		expanded,
		handleExpansion,
		path,
		rootSchema,
		schema,
		uischema,
		uischemas,
		renderers,
		cells,
		config,
	} = props;

	const foundUISchema = useMemo(
		() => findUISchema(uischemas, schema, uischema.scope, path, undefined, uischema, rootSchema),
		[uischemas, schema, uischema.scope, path, uischema, rootSchema]
	);

	return (
		<Accordion aria-labelledby={labelHtmlId} expanded={expanded} onChange={handleExpansion(childPath)}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Grid container alignItems={'center'}>
					<Grid item xs={7} md={9}>
						<Grid container alignItems={'center'}>
							<Grid item xs={2} md={1}>
								<Avatar aria-label="Index">{index + 1}</Avatar>
							</Grid>
							<Grid item xs={10} md={11}>
								<span id={labelHtmlId}>{childLabel}</span>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</AccordionSummary>
			<AccordionDetails>
				<JsonFormsDispatch
					enabled={enabled}
					schema={schema}
					uischema={foundUISchema}
					path={childPath}
					key={childPath}
					renderers={renderers}
					cells={cells}
				/>
			</AccordionDetails>
		</Accordion>
	);
};

const ChildLayoutRenderer = React.memo(ChildRendererComponent);

/**
 * Map state to control props.
 * @param state the JSON Forms state
 * @param ownProps any own props
 * @returns {StatePropsOfControl} state props for a control
 */
export const withContextToExpandPanelProps =
	(Component) =>
	({ ctx, props }) => {
		const { childLabelProp, schema, path, index, uischemas } = props;
		const childPath = composePaths(path, `${index}`);
		const childData = Resolve.data(ctx.core.data, childPath);
		const childLabel = childLabelProp
			? get(childData, childLabelProp, '')
			: get(childData, getFirstPrimitiveProp(schema), '');

		return <Component {...props} childLabel={childLabel} childPath={childPath} uischemas={uischemas} />;
	};

export const withJsonFormsExpandPanelProps = (Component) =>
	withJsonFormsContext(withContextToExpandPanelProps(Component));

export default withJsonFormsExpandPanelProps(ChildLayoutRenderer);
