import { useCallback } from "react";
import {
    ArrayLayoutProps,
    composePaths,
    createDefaultValue,
    isObjectArrayWithNesting,
    RankedTester,
    scopeEndsWith,
    rankWith,
} from "@jsonforms/core";
import { withJsonFormsArrayLayoutProps } from "@jsonforms/react";
import _ from "lodash";
import {LanguageVariantRenderer} from "./LanguageVariantRenderer";
import React from "react";

export const LanguageVariantsRenderer = ({
                                        visible,
                                        enabled,
                                        id,
                                        uischema,
                                        schema,
                                        label,
                                        rootSchema,
                                        renderers,
                                        cells,
                                        data,
                                        path,
                                        errors,
                                        uischemas,
                                    }) => {

    const toRender = Array(data)
        .fill(0)
        .map((_, i) => {
            return (
                <LanguageVariantRenderer
                    key={i}
                    index={i}
                    schema={schema}
                    uischema={uischema}
                    path={composePaths(path, `${i}`)}
                    renderers={renderers}
                    cells={cells}
                />
            );
        });
    return (
        <div>
            {toRender}
        </div>
    );
};

export const languageVariantsTester = rankWith(
    5,
    scopeEndsWith('languageVariants')
);
export default React.memo(
    withJsonFormsArrayLayoutProps(LanguageVariantsRenderer),
    (prevProps, props) => _.isEqual(prevProps, props)
);
