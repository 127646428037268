import clsx from 'clsx';

export const Button = ({ primary = false, children, ...props }) => {
	return (
		<button
			data-testid="button"
			className={clsx({
				'flex relative h-[32px] px-[16px] rounded items-center text-xs font-semibold cursor-pointer uppercase': true,
				'bg-[#3843D0] hover:opacity-80 border-[1px] border-[#3843D0] text-white': primary,
				'bg-white hover:bg-[#E9EAFB] border-[1px] border-[#3843D0] text-[#3843D0]': !primary,
			})}
			{...props}
		>
			{children}
		</button>
	);
};
