import React, { Component } from "react";
import { connect } from "react-redux";
import qString from "query-string";
import { CircularProgress } from "@mui/material";
import {
  setAuthToken,
  manageContenfulAuth
} from "../actions/auth";
import API from "../api/api";

// handle the redirect that comes back from authing to Scrive or Hubspot.
class RedirectFromAuth extends Component {
  constructor(props) {
    super(props);

    // example redirect search params parsed with query-string:
    // {"code":"5e0cb3468d0e96d7_13885", "state":"app:hubspot"}

    // check whether the redirect is coming from Stack or Hubspot based on the "state" variable
    // check hash if coming from Contentful
    this.params = qString.parse(window.location.search || window.location.hash);

    this.authToken = sessionStorage.getItem("jwt");
    this.api = new API();
    if (this.authToken) {
      this.props.dispatch(setAuthToken(this.authToken));
      this.api.setJwt(this.authToken);
    }
  }

  async componentDidMount() {
    if (this.params.code || this.params.access_token) {
      // Authenticate target entity
      const type = this.props.match.params.app;
      const response = await this.api.listIntegrations();
      let targetEntity;

      if (this.params.access_token) {
        const foundContentfulIntegration = response.integrations.find(
          (integration) => integration.config.type === "contentful"
        );

        if (foundContentfulIntegration) {
          // An integration already exists, send user to dashboard
          return this.props.history.push("/integrations");
        }

        targetEntity = await this.api.authorize(type, {
          access_token: this.params.access_token,
          ...this.params,
        });
      } else {
        targetEntity = await this.api.authorize(type, {
          code: this.params.code,
          ...this.params,
        });
      }

      let primaryEntity;
      const foundUnbabelEntity = response.entities.authorized.find(
        (entity) => entity.type === "unbabel"
      );
      if (foundUnbabelEntity) {
        primaryEntity = foundUnbabelEntity.id;
      } else {
        primaryEntity = targetEntity.entity_id;
      }

      const config = {
        type,
        category: "CRM"
      };

      // TODO change, for now using the target entity twice
      const integration = await this.api.createIntegration(
        primaryEntity,
        targetEntity.entity_id,
        config
      );

      if (!integration.error) {
        if (this.params.access_token) {
          // Send user to pick space first
          this.props.dispatch(manageContenfulAuth(integration.id, targetEntity.spaces));
          this.props.history.push("/manage-auth/contentful");
        } else {
          this.props.history.push("/integrations");
        }
      } else {
        alert(integration.error);
      }
    }
  }

  render() {
    const { app } = this.props.match.params;
    let message = 'Hold on, we\'re creating your integration ';

    if (app === 'contentful') {
      message = 'Sit tight, we\'re fetching your spaces ';
    }

    return (
      <div className="container mt-10">
        <div id="card-wrap" className="card">
          <div className="card-body">
            <h1 className="text-3xl font-bold capitalize">{app}</h1>
            <br />
            <p className="flex items-center">{message} <CircularProgress style={{ marginLeft: 10 }} size={20} /></p>
          </div>
        </div>
      </div>
    );
  }
}

// this function is used by redux connect() and tells the component
// which values we want to map from the store into props
function mapStateToProps({ auth }) {
  return {
    authToken: auth.token,
  };
}

// connects this component to the redux store.
export default connect(mapStateToProps)(RedirectFromAuth);
