import React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';

export const Checkbox = ({ value, updateValue }) => {
	return (
		<form>
			<div className="flex items-center" style={{padding: "5px"}}>
				<CheckboxPrimitive.Root
					className="border-[1px] border-[rgb(129,132,137)] flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-[4px] bg-white outline-none"
					checked={value}
					onCheckedChange={() => updateValue(!value)}
				>
					<CheckboxPrimitive.Indicator className="flex bg-blue-600 h-full w-full items-center justify-center">
						<CheckIcon className="h-[20px] w-[20px] text-white font-extrabold" />
					</CheckboxPrimitive.Indicator>
				</CheckboxPrimitive.Root>
			</div>
		</form>
	);
};
