import React, { useEffect, useState } from 'react';

import { Button } from '../ui/Button';

const installHandler = () => {
  window.open('https://chrome.google.com/webstore/detail/unbabel/haiekblbhogojdmdhibmbbekeklcikbn', '_blank', 'noopener');
}

const ChromeExtensionInstalledArea = ({ children, extensionId }) => {
  const installStates = { Resolving: 'resolving', Installed: 'installed', NotInstalled: 'not-installed' };
  const [extensionInstallState, setExtensionInstallState] = useState(installStates.Resolving);

  useEffect(() => {
    fetch(`chrome-extension://${extensionId}/icons/unbabel.png`)
      .then(() => {
        setExtensionInstallState(installStates.Installed);
      })
      .catch(() => {
        setExtensionInstallState(installStates.NotInstalled);
      })
  }, [setExtensionInstallState, extensionId]);

  return (
    <div className="px-3">
      {extensionInstallState === installStates.Resolving && (<Button disabled={true}>Checking install...</Button>)}
      {extensionInstallState === installStates.NotInstalled && (<Button onClick={installHandler}>Install Extension</Button>)}
      {extensionInstallState === installStates.Installed && children}
    </div>
  )
};

export default ChromeExtensionInstalledArea;
