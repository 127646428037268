export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const MANAGE_CONTENTFUL_AUTH = 'MANAGE_CONTENTFUL_AUTH';

// an action for setting the Bearer token once the owner has logged in
export function setAuthToken(token) {
    return {
        type: SET_AUTH_TOKEN,
        token,
    };
}

export function manageContenfulAuth(integrationId, spaces) {
    return {
        type: MANAGE_CONTENTFUL_AUTH,
        integrationId,
        spaces,
    };
}
