import React from "react";
import clsx from "clsx";
import {
  US,
  MX,
  DE,
  FR,
  BR,
  KR,
  NL,
  CN,
  ES,
  ZA,
  IT,
} from "country-flag-icons/react/3x2";

const components = {
  "English (US)": US,
  "Spanish (MX)": MX,
  "German (DE)": DE,
  "French (FR)": FR,
  "Portuguese (BR)": BR,
  "Korean (KR)": KR,
  "Dutch (NL)": NL,
  "Chinese (CN)": CN,
  "Chinese (CN-HANS)": CN,
  "Español (ES)": ES,
  "Afrikaans (ZA)": ZA,
  "Italiano (IT)": IT,
};

export const Locale = ({ primary = false, locale }) => {
  const DynamicFlag = components[locale];
  return (
    <div
      style={{
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "16px",
        overflow: "hidden",
      }}
    >
      {DynamicFlag && (
        <span className="relative overflow-hidden h-[24px] w-[30px]">
          <DynamicFlag
            title={locale}
            className={clsx({
              "border-[2px] rounded h-[24px]": true,
              "border-[#E9EAFB]": primary,
              "border-[#DCEDC8]": !primary,
            })}
          />
        </span>
      )}
      <span
        style={{
          borderTopRightRadius: "4px",
          borderBottomRightRadius: "4px",
          width: "180px",
          margin: "0",
          padding: "0 8px",
          fontSize: "10px",
          fontWeight: "600",
          letterSpacing: "1.25px",
          lineHeight: "24px",
          textAlign: "left",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textTransform: "uppercase",
        }}
        className={clsx({
          "bg-[#E9EAFB] text-[#3843D0]": primary,
          "bg-[#DCEDC8] text-[#33691E]": !primary,
        })}
      >
        {locale}
      </span>
    </div>
  );
};
