import { React, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Chat } from '../components/Unbabel'

function UnbabelPage(props) {

	const [unbabel, setUnbabel] = useState(false)
	useEffect(() => {
		const jwt = props.authToken || sessionStorage.getItem('jwt');
		if (!jwt) {
			props.history.push('/logout');
		}
		const integrations = props?.integrations.integrations.integrations || []
		for (const integration of integrations) {
			if (integration.config.type === 'unbabel') {
				setUnbabel(integration)
			}
		}

	}, []);

	return (
		<main className="h-full pb-16 overflow-y-auto">
			<div className="container px-6 mx-auto grid">
				<h2 className="my-6 text-2xl font-semibold text-gray-700">Unbabel Demonstration</h2>
				<div className="grid gap-6 lg:col-span-1 lg:grid-cols-1 xl:col-span-2 xl:grid-cols-2 2xl:col-span-3 2xl:grid-cols-3 2xl:grid-rows-6">
					{!unbabel && (
						<p>No Unbabel integration found, please connect to an Unbabel account first.</p>
					)}
					{unbabel && (
                        <Chat
                            authToken={props.authToken}
                            integration={unbabel}
                        />
                    )}
				</div>
			</div>
		</main>
	);
}

function mapStateToProps({ auth, integrations }) {
	return {
		authToken: auth.token,
		integrations
	};
}

export default connect(mapStateToProps)(UnbabelPage);
