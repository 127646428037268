import React, { useEffect, useState } from 'react';

import { ActionMapper } from './ActionMapper';

const Action = ({ integrationId, actionId }) => {
    const [extensionId, setExtensionId] = useState(null);
    const extensionIdKey = 'unbabel-extension-id';

    useEffect(() => {
        const onChangeExtensionId = (event) => {
            if (event.key === extensionIdKey) {
                setExtensionId(event.newValue);
            }
        };
        window.addEventListener('storage', onChangeExtensionId);
    }, [extensionIdKey]);

    useEffect(() => {
        const currentExtensionId = sessionStorage.getItem(extensionIdKey);
        setExtensionId(currentExtensionId);
        return () => {
            setExtensionId(null);
        };
    }, [extensionIdKey]);

    const MappedAction = ActionMapper[actionId];
    return <MappedAction integrationId={integrationId} extensionId={extensionId} />
};

export const Type = 'chrome-extension';

export default Action;
