const listeners = {};

const useEvent = ({ name }) => {
  const subscribe = (func) => {
    const newListener = {
      id: Date.now(),
      func,
    };
    if (!listeners[name]) {
      listeners[name] = [];
    }

    listeners[name].push(newListener);
    return newListener.id;
  };

  const unsubscribe = (id) => {
    if (listeners[name]) {
      listeners[name].splice(
        listeners[name].findIndex((item) => item.id === id),
        1
      );
    }
  };

  const emit = (data) => {
    if (listeners[name]) {
      listeners[name]?.forEach((listener) => {
        try {
          listener.func(data);
        } catch (err) {
          console.log(err);
        }
      });
    }
  };

  return {
    subscribe,
    unsubscribe,
    emit,
  };
};

export default useEvent;
