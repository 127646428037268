import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch, withRouter } from 'react-router-dom';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Data from './components/Data';
import Logout from './components/Logout';
import AuthRedirect from './components/AuthRedirect';
import IntegrationsPage from './pages/IntegrationsPage';
import IntegrationPage from './pages/IntegrationPage';
import SettingsPage from './pages/SettingsPage';
import ManageAuthPage from './pages/ManageAuthPage';
import UnbabelPage from './pages/UnbabelPage';
import Playground from './pages/Playground';

const AppRouter = (props) => {
	return (
		<>
			<Sidebar />
			<div className="flex flex-col flex-1">
				<Navbar />
				<Switch>
					<Route path="/unbabel" exact component={UnbabelPage} />
					<Route path="/playground" exact component={Playground} />
					<Route path="/integrations" exact component={IntegrationsPage} />
					<Route path="/integrations/:integrationId" exact component={IntegrationPage} />
					<Route path="/settings" exact component={SettingsPage} />
					<Route path="/data/:integrationId" exact component={Data} />:
					<Route path="/manage-auth/:app" exact component={ManageAuthPage} />
					<Route path="/redirect/:app" exact component={AuthRedirect} />
					<Route path="/logout" exact component={Logout} />
					<Redirect to="/integrations" />
				</Switch>
			</div>
		</>
	);
};

export default withRouter(AppRouter);
