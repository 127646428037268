import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import { Drawer, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useMemo, useState } from "react";
import API from "../../api/api";
import { SwitchControl } from "../controls/SwitchControl";
import { vanillaRenderers } from "@jsonforms/vanilla-renderers";
import { SelectControl } from "../controls/SelectControl";
import { SelectControlSpaces } from "../controls/SelectControlSpaces";
import {toast} from "react-toastify";
import useEvent from "../../hooks/useEvent";
import { format } from "date-fns";
import "./unbabel.css"

const renderersUI = [
  ...materialRenderers,
  ...vanillaRenderers,
  //register custom renderers
  SwitchControl,
  SelectControl,
  SelectControlSpaces,
];

const UnbabelSettingsDrawer = ({ integrationId, ...props }) => {
  const key = `last_updated_${integrationId}`;
  const [integration, setIntegration] = useState(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);
  //   const integrationId = integration?.id;
  const [jsonSchema, setJsonSchema] = useState({});
  const [uiSchema, setUiSchema] = useState({});
  const { emit } = useEvent({ name: `INTEGRATION_UPDATED_${integrationId}` });

  useEffect(() => {
    try {
      const date = window.localStorage.getItem(key);
      if (date) {
        setLastUpdated(new Date(date));
      }
    } catch (err) {
      console.log(err);
    }
  }, [integrationId]);

  const toggleDrawer = async (open) => {
    setOpen(open);

    const jwt = sessionStorage.getItem("jwt");
    const api = new API();
    if (open && jwt) {
      api.setJwt(jwt);

      setIsLoading(true);

      let jsonSchema, uiSchema, pipelineGroupLanguageCodes;

      try {
        ({ jsonSchema, uiSchema, pipelineGroupLanguageCodes } = await api.getIntegrationConfigOptions(integrationId));
      } catch(e) {
        return toast.error('Oops, something went wrong. Please try again.');
      } finally {
        setIsLoading(false);
      }

      setJsonSchema({
        ...jsonSchema,
        pipelineGroupLanguageCodes: pipelineGroupLanguageCodes || {},
      });

      setUiSchema(uiSchema || {});
    }
  };

  const onSave = async () => {
    const api = new API();
    api.setJwt(sessionStorage.getItem("jwt"));

    try {
      await api.updateIntegration(integrationId, data);
      emit();
      window.localStorage.setItem(key, new Date().toISOString());
      setLastUpdated(new Date());
      toggleDrawer(false);
      toast.success('Changes saved successfully!');
    } catch (e) {
      return toast.error('Oops, something went wrong. Please try again.');
    }

    setTimeout(async () => {
      props.finishUpdate();
    }, 1000);
  };

  useEffect(async () => {
    const jwt = sessionStorage.getItem("jwt");
    const api = new API();
    if (jwt) {
      api.setJwt(jwt);

      const pipelines = await api.getIntegrationConfigOptions(integrationId);
      const integrationData = await api.getIntegration(integrationId);

      if (integrationData.status === 'NEEDS_CONFIG') {
        toggleDrawer(true);
      }

      setIntegration(integrationData);
      setData(integrationData.config);
    }
  }, [integrationId]);

  const updateData = ({ errors, data }) => {
    setIsSaveEnabled(errors.length === 0);
    setData(data);
  };

  const body = isLoading ? (
    <>
      <div className="flex justify-between align-items-center mb-[10px]">
        <div className="mt-4 w-48 h-10 bg-gray-400 animate-pulse"></div>
        <IconButton onClick={() => toggleDrawer(false)}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="mb-[24px]">
        <div className="w-36 h-5 bg-gray-400 animate-pulse"></div>
      </div>
      <div
        className="bg-white px-[24px] py-[16px] rounded-[4px] mb-[24px]"
        style={{
          boxShadow:
          "0px 1px 6px 0px #696C7208, 0px 1px 10px 0px #696C7217",
        }}
      >
        <div className="w-50 h-10 bg-gray-400 animate-pulse"></div>
        <div className="mt-4 w-50 h-12 bg-gray-400 animate-pulse"></div>
        <div className="mt-1 w-50 h-12 bg-gray-400 animate-pulse"></div>
      </div>
      <div className="flex justify-end">
        <div className="mr-5 w-20 h-10 bg-gray-400 animate-pulse"></div>
        <div className="w-24 h-10 bg-gray-400 animate-pulse"></div>
      </div>
    </>
  ) : (
    <>
      <div className="flex justify-between align-items-center mb-[10px]">
        <h2 className="text-2xl capitalize italic font-thin" data-testid="integration-settings-title">
          {integration?.config?.type} Settings
        </h2>
        <IconButton onClick={() => toggleDrawer(false)}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="mb-[24px]">
        {lastUpdated ? (
          <h2 className="text-[12px]" style={{ color: "#343941" }}>
            Last updated: {format(lastUpdated, 'dd MMMM yyyy')}
          </h2>
        ) : null}
      </div>
      <div
        className="bg-white px-[24px] py-[16px] rounded-[4px] mb-[24px]"
        style={{
          boxShadow:
          "0px 1px 6px 0px #696C7208, 0px 1px 10px 0px #696C7217",
        }}
      >
        {data && (
          <JsonForms
            schema={jsonSchema}
            data={data}
            renderers={renderersUI}
            cells={materialCells}
            onChange={updateData}
          />
        )}
      </div>
      <div className="flex justify-end">
        <button
          onClick={() => toggleDrawer(false)}
          className="px-[16px] py-[6px] mr-[16px] rounded-2 hover:opacity-[0.8]"
          style={{
            border: "1px solid #3843D0",
            color: "#3843D0",
            fontSize: 16,
          }}
        >
          Cancel
        </button>
        <button
          onClick={onSave}
          disabled={!isSaveEnabled}
          className={`px-[16px] py-[6px] rounded-2 ${isSaveEnabled && 'hover:opacity-[0.8]'}`}
          style={{
            backgroundColor: (isSaveEnabled ? "#3843D0" : "#EBEBE4"),
            color: (isSaveEnabled ? "white" : "gray"),
            fontSize: 16,
          }}
        >
          Save Changes
        </button>
      </div>
    </>
  );

  return (
    <>
      <button
        style={{ color: "#3843D0", fontSize: 18 }}
        onClick={() => toggleDrawer(true)}
      >
        Settings
        <SettingsIcon sx={{ fontSize: 18, ml: 1 }} />
      </button>
      <Drawer anchor={"right"} open={open} onClose={() => toggleDrawer(false)}>
        <div
          style={{
            backgroundColor: "#E7E7E8",
          }}
          className="w-[50vw] h-full px-[24px] py-[16px]"
        >
          {body}
        </div>
      </Drawer>
    </>
  );
};

export default UnbabelSettingsDrawer;
