import { React, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import * as Tabs from "@radix-ui/react-tabs";

import "./tempTabs.css";
import { ActionMapper } from "../components/ActionMapper";
import { SettingsDrawer } from "../components/Unbabel";
import { Paper } from "@mui/material";

function IntegrationPage(props) {
  const location = useLocation();
  const { integration } = location.state;
  const { name, description, category, icon } = integration.display;
  const {
    type,
    hasUserConfig,
    status: initialStatus,
    userActions,
  } = integration;
  const [tabsListTriggers, setTabsListTriggers] = useState([]);
  const [tabsContent, setTabsContent] = useState([]);
  const [defaultTab, setDefaultTab] = useState(
    Object.keys(userActions)[0] || ""
  );

  useEffect(async () => {
    const jwt = props.authToken || sessionStorage.getItem("jwt");
    if (!jwt) {
      props.history.push("/logout");
    }
    await renderActionTabs();
  }, []);

  const renderActionTabs = async () => {
    const tabsContent = [];
    const tabsListTriggers = [];
    const MappedAction =
      ActionMapper[integration.type] || ActionMapper.defaultAction;

    for (const action in userActions) {
      tabsListTriggers.push(
        <Tabs.Trigger
          className="TabsTrigger px-3 py-4"
          value={action}
          key={action}
          asChild
        >
          <li type={""} key={action}>
            <span className="text-base font-thin italic text-black">
              {userActions[action].label}
            </span>
          </li>
        </Tabs.Trigger>
      );
      tabsContent.push(
        <Tabs.Content value={action}>
          {/* <p className="Text">{userActions[action].description}</p> */}
          <MappedAction
            integrationId={integration.id}
            actionId={action}
            key={integration.id}
          />
        </Tabs.Content>
      );
    }
    setTabsListTriggers(tabsListTriggers);
    setTabsContent(tabsContent);
  };

  const finishUpdate = () => {
    props.history.push("/integrations");
    // Need to refetch all integrations to get their updated state
    window.location.reload();
  };

  return (
    <main
      className="h-full pb-16 overflow-y-auto integration-page"
      style={{ maxWidth: "100vw" }}
    >
      <div className="container px-6 mx-auto grid">
        <div className="my-6 flex flex-wrap">
          <ul className="flex">
            <li className="inline-flex">
              <Link to="/integrations" className="hover:text-blue-500 text-sm">
                Integrations
              </Link>

              <svg
                className="w-4 h-auto fill-current mx-2 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" />
              </svg>
            </li>

            <li className="inline-flex">
              <span href="#" className="text-sm">
                {name}
              </span>
            </li>
          </ul>
        </div>

        <Paper className="bg-white pt-2" >
          <div className="flex w-full rounded bg-transparent align-items-center justify-content-between p-2 px-3">
            <div className="inline-flex">
              <div className="flex flex-wrap content-center justify-center rounded">
                <Link to="/integrations">
                  <img
                    className="max-w-[44px] max-h-[44px] rounded h-[12px]"
                    alt={"Back icon"}
                    src={"/assets/media/Back_arrow.svg"}
                  />
                </Link>
              </div>

              <div className="ml-4 flex-col">
                <h2 className="text-xl font-bold italic text-[#343941]">
                  {name}
                </h2>
                {/* <p className="font-medium text-gray-600">{description}</p> */}
              </div>
            </div>
            <div>
              {integration ? (
                <SettingsDrawer
                  integrationId={integration.id}
                  finishUpdate={finishUpdate}
                />
              ) : null}
            </div>
          </div>

          <Tabs.Root
            className="flex w-full mt-3 bg-transparent TabsRoot"
            defaultValue={defaultTab}
          >
            <Tabs.List className="TabsList" aria-label="Available User Actions">
              {tabsListTriggers}
            </Tabs.List>
            {tabsContent}
          </Tabs.Root>
        </Paper>
      </div>
    </main>
  );
}

function mapStateToProps({ auth }) {
  return {
    authToken: auth.token,
  };
}

export default connect(mapStateToProps)(IntegrationPage);
